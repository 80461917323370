import request from '@/utils/request.js'

//获取摄像头的序列号
export function getSerialNumber(did) {
  return request({
    url: '/api/v1/devices/' + did + '/video',
    method: 'get'
  })
}
//获取萤石云token
export function getAccessToken() {
  return request({
    url: '/api/v1/devices/token',
    method: 'get'
  })
}
