<template>
  <div class="right-contaier">
    <span style="display: none">{{ equipment }}{{ uid }}</span>
    <div class="height-50">
      <border-box :src="srcTop">
        <span slot="title" class="title border-title">设备综合健康度</span>
        <div slot="box-container">
          <pieChart
            chartId="设备综合健康度"
            :chartData="chartData"
            :chartOption="chartOption"
            stype="width:100%;height:100%;"
          />
        </div>
      </border-box>
    </div>
    <div class="height-50">
      <border-box :src="srcBottom">
        <span slot="title" class="title border-title">实时监控</span>
        <div slot="box-container" class="box-container abc">
          <div id="video-container"></div>
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import pieChart from '../../components/pieChart.vue'
import srcTop from '@/assets/img/add.png'
import srcBottom from '@/assets/img/monitor.png'

import { getDeviceData } from '@/api/list'
import { getAccessToken, getSerialNumber } from '@/api/online'

import EZUIKit from 'ezuikit-js'

export default {
  components: { pieChart },
  data() {
    return {
      srcTop,
      srcBottom,
      chartOption: {
        height: '300px',
        legend: {
          itemGap: 26,
          itemWidth: 26,
          itemHeight: 15,
          textStyle: {
            rich:{
              name:{
                fontSize: 18
              }
            }
          }
        },
        color: ['#3ECCAE', '#207AE8', '#FFFB29', '#F06E24']
      },
      chartData: [],
      sourceData: []
    }
  },
  computed: {
    equipment() {
      const equipment = this.$store.state.equipment
      this.initVideo(equipment)
      return equipment
    },
    uid() {
      const uid = this.$store.state.uid
      if (uid) {
        this.getDevices(uid)
      }
      return uid
    }
  },
  mounted() {
    this.getChartData()
    this.initVideo()
  },
  methods: {
    //切换
    change() {
      player.stop()
      // 切换为直播
      player.play({
        url: 'ezopen://open.ys7.com/244640009/1.live'
      })
    },
    currentTime() {
      this.systemDateTime = new Date().toLocaleString()

      this.timerID && clearInterval(this.timerID)

      this.timerID = setInterval(() => {
        this.systemDateTime = new Date().toLocaleString()
      }, 1000)
    },
    //获取摄像头的序列号
    async initVideo(equipment) {
      let videoToken = await getAccessToken() //获取摄像头的token
      videoToken = videoToken.data.accessToken

      let serialNumber = await getSerialNumber(equipment || this.equipment)
      this.videoPlayer = new EZUIKit.EZUIKitPlayer({
        id: 'video-container', // 视频容器ID
        accessToken: videoToken,
        // url:`ezopen://${item.identifyingCode}@open.ys7.com/${item.imei}/${item.channelNo}.hd.live`,
        url: 'ezopen://open.ys7.com/' + serialNumber + '/1.live',
        template: 'security'
      })
    },

    // 设备列表
    getDevices(uid) {
      getDeviceData({ id: uid }).then((res) => {
        this.sourceData = res
        if (this.equipment) {
          this.getChartData()
        }
      })
    },
    // 获取设备综合健康度
    getChartData() {
      let chartData =
        this.sourceData.find((el) => {
          return el.id == this.equipment
        }) || {}
      this.chartData = chartData?.data?.assessment || []
    }
  }
  // watch:{
  //   equipment(val){
  //     // this.getChartData()
  //     // this.initVideo()
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.right-contaier {
  font-size: 16px;
  color: #0d9bd8;
}
.box-container {
  padding: 14px;
}
video {
  width: 411px;
  height: 270px;
}
.btn-group {
  width: 100%;
  height: 22px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .img {
    width: 20px;
    height: 20px;
    margin-left: 14px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .btn {
  }
}
::v-deep .header-controls {
  background: transparent !important;
}
::v-deep #video-container {
  width: 100% !important;
  height: 250px !important;
  div {
    width: 100% !important;
    height: 250px !important;
    background-color: transparent !important;
  }
}
</style>
