<template>
  <!-- 实时检测中间的柱状图 -->
  <div>
    <div
      class="line-chart"
      :id="chartId"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    chartData: {
      style: Array,
      default: []
    },
    keys: {
      style: Array,
      default: []
    },
    chartId: {
      style: String,
      default: ''
    },
  },
  data() {
    return {
      chart: null,
      options: {}, 
      color: [
        'rgba(30, 208, 248, 1)',
        'rgba(43, 81, 255, 1)',
        'rgba(133, 80, 243,1)',
        'rgba(226, 58, 245,1)',
        'rgba(255, 164, 46, 1)',
        'rgba(255, 238, 4, 1)'
      ]
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resize)
  },
  watch: {
    chartData: function (val, valOld) {
      this.updateChart()
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updateChart: function () {
      const _this = this
      let top = []
      const max = Math.floor(Math.max(...this.chartData)) + 10
      top = Array.from({ length: this.chartData.length }).map((item) => {
        return max
      })
      const CubeLeft = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c0 = [shape.x, shape.y - 15]
          const c1 = [shape.x - 30, shape.y - 30]
          const c2 = [xAxisPoint[0] - 30, xAxisPoint[1] - 15]
          const c3 = [xAxisPoint[0], xAxisPoint[1]]
          ctx
            .moveTo(c0[0], c0[1])
            .lineTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .closePath()
        }
      })
      const CubeRight = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x, shape.y - 15]
          const c2 = [xAxisPoint[0], xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + 30, xAxisPoint[1] - 15]
          const c4 = [shape.x + 30, shape.y - 30]
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath()
        }
      })
      const CubeTop = echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function (ctx, shape) {
          const c1 = [shape.x, shape.y - 15]
          const c2 = [shape.x + 30, shape.y - 30]
          const c3 = [shape.x, shape.y - 40]
          const c4 = [shape.x - 30, shape.y - 30]
          ctx
            .moveTo(c1[0], c1[1])
            .lineTo(c2[0], c2[1])
            .lineTo(c3[0], c3[1])
            .lineTo(c4[0], c4[1])
            .closePath()
        }
      })
      echarts.graphic.registerShape('CubeLeft', CubeLeft)
      echarts.graphic.registerShape('CubeRight', CubeRight)
      echarts.graphic.registerShape('CubeTop', CubeTop)
      this.options = {
        xAxis: {
          type: 'category',
          data: this.keys,
          offset: 20,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 14,
            color: '#1ED0F8'
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            fontSize: 16,
            show: false
          },
          offset: 16,
          boundaryGap: ['0%', '20%']
        },
        series: [
          // 柱形背景
          {
            z:-3,
            type: 'custom',
            renderItem: function (params, api) {
              const location = api.coord([api.value(0), api.value(1)])
              return {
                type: 'group',
                children: [
                  {
                    type: 'CubeLeft',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: 'rgba(14, 42, 92, 1)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(14, 42, 92, 0)'
                        }
                      ])
                    }
                  },
                  {
                    type: 'CubeRight',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: 'rgba(14, 42, 92, 1)'
                        },
                        {
                          offset: 1,
                          color: 'rgba(14, 42, 92, 0)'
                        }
                      ])
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: 'rgba(72,156,221,.27)'
                    }
                  }
                ]
              }
            },
            data: top
          },
          // 柱形填充
          {
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              return {
                type: 'group',
                children: [
                  {
                    type: 'CubeLeft',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: _this.color[params.dataIndex]
                        },
                        {
                          offset: 1,
                          color: 'rgba(14, 42, 92, 0.8)'
                        }
                      ])
                    }
                  },
                  {
                    type: 'CubeRight',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: _this.color[params.dataIndex]
                        },
                        {
                          offset: 1,
                          color: 'rgba(14, 42, 92, 0.8)'
                        }
                      ])
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      api,
                      xValue: api.value(0),
                      yValue: api.value(1),
                      x: location[0],
                      y: location[1],
                      xAxisPoint: api.coord([api.value(0), 0])
                    },
                    style: {
                      fill: _this.color[params.dataIndex]
                      // fill: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      //   {
                      //     offset: 0,
                      //     color: this.color[params.dataIndex]
                      //   },
                      //   {
                      //     offset: 1,
                      //     color: 'rgba(14, 42, 92, 0.1)'
                      //   }
                      // ])
                    }
                  }
                ]
              }
            },
            data: this.chartData
          },
          // 激活背景
          {
            type: 'bar',
            // label: {
            //   normal: {
            //     show: true,
            //     position: 'top',
            //     fontSize: 16,
            //     color: '#fff',
            //     offset: [0, -45]
            //   }
            // },
            itemStyle: {
              color: 'transparent'
            },
            z: -2,
            data: top
          },
          {
            type: 'pictorialBar',
            symbolSize: ['100%', 20],
            symbolOffset: [0, 10],
            z: -1,
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 16,
                color: '#fff',
                offset: [0, -45]
              }
            },
            itemStyle: {
                color: 'transparent', 
                opacity: 1
            },
            data: this.chartData
          }
        ]
      }
      this.options.series[2].itemStyle.color = (data) => {
        if (data.dataIndex === 0)
        // LinearGradient
          return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'transparent',
              opacity: 1
            },
            {
              offset: 1,
              color: this.color[data.dataIndex],
              opacity: 1
            }])
        else {
          return 'transparent'
        }
      }
      this.options.series[3].itemStyle.color = (data) => {
        if (data.dataIndex === 0)
          return this.color[data.dataIndex]
        else {
          return 'transparent'
        }
      }
      
      this.chart.setOption(this.options, true)
    },
    initChart() {
      const target = document.getElementById(this.chartId)
      this.chart = echarts.init(target)

      this.chart.on('click', (params) => {
        this.$parent.dataIndex = params.dataIndex
        this.options.series[2].itemStyle.color = (data) => {
          if (params.dataIndex == data.dataIndex)
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'transparent',
                opacity: 1
              },
              {
                offset: 1,
                color: this.color[data.dataIndex],
                opacity: 1
              }
            ])
          else {
            return 'transparent'
          }
        }
        this.options.series[3].itemStyle.color = (data) => {
        if (params.dataIndex == data.dataIndex)
          return this.color[data.dataIndex]
        else {
          return 'transparent'
        }
        }

        this.chart.setOption(this.options, true)
      })
      this.updateChart()
    },
    resize() {
      if (this.chart) this.chart.resize()
    }
  }
}
</script>
