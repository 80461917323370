<template>
  <div class="wrapper-monitor">
    <div class="monitor-left">
      <left />
    </div>
    <div class="monitor-main">
      <middle />
    </div>
    <div class="monitor-right">
      <right style="width: 100%; height: 100%" />
    </div>
  </div>
</template>
<script>
import progressChart from '../../components/progressChart'
import left from './left'
import middle from './middle'
import right from './right'
export default {
  components: { progressChart, left, middle, right },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper-monitor {
  width: 100%;
  height: 80%;
  padding-right: 46px;
  /* padding-bottom: 46px; */
  padding-left: 46px;
  display: flex;
  color: #1ed0f8 !important;
  font-family: 'PingFangSC' serif;

  .monitor-left {
    height: 100%;
    flex: 0 1 25%;
  }

  .monitor-main {
    height: 100%;
    flex: 0 1 50%;
  }
  .monitor-right {
    height: 100%;
    flex: 0 1 25%;
  }
}
</style>
