<template>
  <div class="status">
    <div class="height-50">
      <p class="status-border-title">{{ equipment }}设备当前状态</p>
      <barChart
        chartId="设备当前状态"
        :keys="barKeys"
        :chartData="barData"
        style="width: 100%; height: 380px"
      />
    </div>
    <div class="height-50">
      <border-box :src="src">
        <div slot="title" class="title-box">
          <span class="title border-title">
            轿厢{{ titles[dataIndex] }}变化趋势</span
          >
          <!-- <p class="btn-group">
            <img src="@/assets/img/add.png" alt="" />
            <img src="@/assets/img/add.png" alt="" />
            <img src="@/assets/img/add.png" alt="" />
            <img src="@/assets/img/add.png" alt="" />
          </p> -->
        </div>
        <div slot="box-container">
          <lineChart
            chartId="轿厢变化趋势"
            :keys="lineKeys"
            :chartData="lineData"
            :chartOption="lineOption"
            chartType="line"
            style="width: 100%; height: 340px"
            ref="table"
          />
        </div>
      </border-box>
    </div>
  </div>
</template>

<script>
import barChart from '@/views/components/barChart.vue'
import lineChart from '@/views/components/lineChart.vue'

import { getThresholdRecordData } from '@/api/forecast'

export default {
  components: { barChart, lineChart },
  data() {
    return {
      dataIndex: 0,
      titles: [],
      barKeys: [],
      sourceData: [],
      barData: [],
      lineData: [],
      lineKeys: [],
      lineOption: {
        color: ['rgba(0,198, 255,1)', 'rgba(0,198, 255,0)'],
        lineWidth:2,
        yAxis:{
          min: 0
        }
      },
      src: require('@/assets/img/line-chart.png')
    }
  },
  computed: {
    equipment() {
      const equipment = this.$store.state.equipment
      if (equipment) {
        this.getTop(equipment)
      }
      return equipment
    },
    uid(){
      const uid = this.$store.state.uid
      if (uid){
        this.getDevices(uid)
      }
      return uid
    },
  },
  methods: {
    getTop(equipment) {
      getThresholdRecordData(equipment)
        .then((res) => {
          this.sourceData = res
          res.forEach((item) => {
            this.barKeys.push(item.item)
            this.titles = this.barKeys.map((el) => {
              return el.split('(')[0]
            })
            this.barData.push(item.barData)
            this.lineKeys = this.sourceData[this.dataIndex].itemDates
            this.lineData = this.sourceData[this.dataIndex].itemValues
          })
        })
        .catch(() => {
          this.barKeys = []
          this.sourceData = {}
          this.titles = []
          this.barData = []
        })
    }
  },
  watch: {
    dataIndex(val) {
      this.lineKeys = this.sourceData[val].itemDates
      this.lineData = this.sourceData[val].itemValues
    }
  }
}
</script>

<style scoped lang="scss">
.status {
  width: 100%;
  height: 100% !important;
  font-family: PingFangSC;
  color: #1ed0f8;

  background-image: url('../../../assets/img/grid-bg.png');
  background-size: 100% 120%;
  background-position: 50% 0%;
  background-repeat: no-repeat;

  .status-border-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    font-size: 28px;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 4px;
    /* // 背景线性渐变 */
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(30, 208, 248, 1) 100%
    );
    /* // 兜底颜色，防止文字裁剪不生效 */
    color: #ffffff;
    background-clip: text;
    /* // 背景被裁减为文字的前景色 */
    -webkit-background-clip: text;
    /* // 文字填充为透明，优先级比color高。 */
    -webkit-text-fill-color: transparent;
  }
  .title-box {
    width: 100%;
    height: 50px;
    overflow: hidden;
  }
  .btn-group {
    float: right;
    line-height: 50px;
    img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    img + img {
      margin-left: 16px;
    }
  }
}
</style>
