<template>
  <borderBox
    style="width: 100%; height: 100%"
    class="equipment-info border-box-content"
    :src="src"
  >

  <span style="display:none;">{{equipment}}</span>
    <span slot="title" class="title border-title">设备信息</span>
    <div slot="box-container">
      <div class="chart-box">
        <!-- TODO 原系统没有状态 -->
        <progressChart
          :chartData="progressData"
          chartId="当前状态"
          :chartOption="chartOptionStatus"
          :unit="unit"
          style="width: 100%; height: 100%"
        />
        <progressChart
          :chartData="[portData.speedLimit || 0]"
          chartId="额定速度（km/h）"
          :chartOption="chartOptionSpeedLimit"
          style="width: 100%; height: 100%"
        />
        <progressChart
          :chartData="[portData.speed || 0]"
          chartId="当前速度（km/h）"
          :chartOption="chartOptionSpeed"
          style="width: 100%; height: 100%"
        />
      </div>
      <div class="line"></div>
      <div class="grid primary-box">
        <p>当前楼层</p>
        <p>
          {{ portData.floorSign || 0 }}
          F
        </p>
        <p>梯门状态</p>
        <p>
          {{
            portData.doorSignal == 0
              ? '关门到位'
              : portData.doorSignal == 1
              ? '关门中'
              : portData.doorSignal == 2
              ? '开门到位'
              : '开门中'
          }}
        </p>
        <p>当前高度</p>
        <p>
          {{ portData.position || 0 }}
          mm
        </p>
        <p>运行方向</p>
        <p>
          {{
            portData.direction == 0
              ? '停止'
              : portData.direction == 1
              ? '上行'
              : '下行'
          }}
        </p>
        <p>制动时长</p>
        <p>
          {{ portData.brakeTime || 0 }}
          s
        </p>
        <p>曳引机电流</p>
        <p>
          {{ portData.tractionCurrent || 0 }}
          A
        </p>
        <p>曳引机电压</p>
        <p>
          {{ portData.tractionVoltage || 0 }}
          V
        </p>
      </div>

      <div class="grid danger-box">
        <p>故障类型</p>
        <p>
          {{ portData.falutCode }}
        </p>
      </div>
    </div>
  </borderBox>
</template>

<script>
import progressChart from '@/views/components/progressChart.vue'
import src from '@/assets/img/add.png'

export default {
  components: { progressChart },
  data() {
    return {
      reconnect: 5,
      src,
      progressData: [80],
      top: 100,
      chartOptionStatus: {
        color: ['#63E2FF']
      },
      chartOptionSpeedLimit: {
        color: ['#FFFB29']
      },
      chartOptionSpeed: {
        color: ['#D94FFF']
      },
      portData:{},
      unit: 'F'
    }
  },
  computed: {
    equipment() {
      const equipment = this.$store.state.equipment
      if (equipment) {
        this.initWebSocket(equipment)
      }
      return equipment
    }
  },
  methods: {
    //初始化socket
    initWebSocket(equipment) {
      const url = `wss://www.elevatorcloud.com.cn/socket/v1/devices/${equipment}/online`
      this.webSocket = new WebSocket(url)
      this.webSocket.onmessage = this.wsOnmessage //监听消息
      this.webSocket.onopen = this.wsOnopen //监听打开事件
      this.webSocket.onerror = this.wsOnerror //监听发生错误事件
      this.webSocket.onclose = this.wsOnclose //监听关闭事件
    },
    wsOnopen() {
      this.webSocket.send(1000)
      console.log('连接成功')
    },
    wsOnerror() {
      console.log(
        this.reconnect ? '连接失败准备重连...' : '已重连5次，不再重连'
      )
      if (this.reconnect) {
        --this.reconnect
        this.initWebSocket()
      }
    },
    wsOnmessage(e) {
      const data = JSON.parse(e.data)
      this.portData = redata
    },
    wsSend(Data) {
      this.webSocket.send(Data)
    },
    wsOnclose(e) {
      console.log('连接已断开')
    },
    //主动断开连接
    wshandleClose() {
      this.webSocket.close()
      this.webSocket = undefined
    }
  },
  destroyed(){
    console.log('销毁ws');
    this.wshandleClose()
  }
}
</script>
<style lang="scss" scoped>
.equipment-info {
  box-title {
    padding-top: 14px;
    padding-left: 13px;
    padding-right: 13px;
  }
  .chart-box {
    width: 100%;
    padding-left: 23px;
    padding-right: 23px;
    margin-bottom: 43px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px;
    grid-row-gap: 10px;
  }
  .line {
    width: 100%;
    height: 2px;
    border-radius: 2px;
    margin-bottom: 46px;
    background: linear-gradient(
      -90deg,
      rgba(30, 208, 248, 0) 0%,
      rgba(30, 208, 248, 1) 52.12%,
      rgba(30, 208, 248, 0) 100%
    );
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px;
    margin-bottom: 34px;
  }
  .primary-box {
    width: 100%;
    padding: 0 38px;
    p {
      border: 1px solid #003aa3;
      line-height: 50px;
      text-align: center;
    }
  }
  .danger-box {
    width: 100%;
    padding: 0 38px;
    margin-top: 46px;
    p {
      border: 1px solid #f06e24;
      color: #f06e24;
      line-height: 50px;
      text-align: center;
    }
  }
}
</style>
